<template>
  <el-dialog title="工作轨迹" :visible.sync="showModel" :close-on-click-modal="false">
    <div> 
        <el-checkbox-group v-model="checkList" @change="layerChange">
            <el-checkbox label="gj">轨迹</el-checkbox>
            <el-checkbox label="gw">管网</el-checkbox>
            <el-checkbox label="pq">片区</el-checkbox>
            <!-- <el-checkbox label="dkd">打卡点</el-checkbox> -->
            <el-checkbox label="sb">设备</el-checkbox>
          </el-checkbox-group>
     </div>
    <div id="mapAdd" style="width: 100%; height: 500px"></div>
  </el-dialog>
</template>

<script>
import { forEach } from "jszip";
import { queryFeatrue } from "../Gis/apis/geo";
import { formatLocation } from "../Gis/utils/geoTool";
// import {getTrackWorkPage} from "@/RequestPort/checkingIn";
export default {
  name: "workLocus",
  data() {
    return {
      showModel: false,
      // 轨迹
      polyline: null,
      // 终点
      markerZhong: null,
      // 起点
      markerqi: null,
      // 管网
      wms: null,
      // 片区
      polygonNet:null,
      // 打卡点
      labelsLayer:null,
      // 设备
      equipmentes:null,
       checkList:['gj','gw','pq','dkd','sb'],
      datarow:{}
    };
  },
  mounted() {
    // (type='line' and id in (1)) or (type='point' and id in (1))
  },
  methods: {
    open(row, val) {
      this.checkList=['gj','gw','pq','dkd','sb']
      let that = this;
      this.datarow = val
      this.showModel = true;
      this.$nextTick(() => {
        // if(!that.map){
        if (val.path && val.path.length > 0) {
          if (val.path[0]) {
            that.map = new AMap.Map("mapAdd", {
              resizeEnable: true,
              center: val.path[0][0][0],
              zoom: 14,
            });
          }
        } else if (val.network && val.network.length > 0) {
          that.map = new AMap.Map("mapAdd", {
            resizeEnable: true,
            center: val.network[0].location[0],
            zoom: 14,
          });
        }
        // } else{
        //     if(this.markerZhong){
        //       this.Map.remove(this.markerZhong);
        //     }
        //     if(this.markerqi){
        //       this.Map.remove(this.markerqi);
        //     }
        //     if(this.polyline){
        //       this.Map.remove(this.polyline);
        //     }
        //   }
        // 巡检轨迹
        this.polyline=new AMap.OverlayGroup()
        if (val.path && val.path.length > 0) {
          for (let i = 0; i < val.path.length; i++) {
            // 起点
            let qidian = new AMap.Icon({
              size: new AMap.Size(58, 70), // 图标尺寸
              image: require("@/assets/start.png"), // Icon的图像地址
              imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
            });
            this.markerqi = new AMap.Marker({
              position: new AMap.LngLat(
                val.path[i][0][0][0],
                val.path[i][0][0][1]
              ),
              offset: new AMap.Pixel(-10, -30),
              icon: qidian, // 添加 Icon 实例
              title: "自定义图标",
              zoom: 13,
            });
            that.map.add(this.markerqi);
            let arr=[]
            for (let j = 0; j < val.path[i].length; j++) {
              if (j == val.path[i].length - 1) {
                // 终点
                let lastLineLength = val.path[i][j].length

                let zhongdian = new AMap.Icon({
                  size: new AMap.Size(58, 70), // 图标尺寸
                  image: require("@/assets/end.png"), // Icon的图像地址
                  imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
                });
               this.markerZhong = new AMap.Marker({
                  position: new AMap.LngLat(
                    val.path[i][j][lastLineLength-1][0],
                    val.path[i][j][lastLineLength-1][1]
                  ),
                  offset: new AMap.Pixel(-10, -30),
                  icon: zhongdian, // 添加 Icon 实例
                  title: "自定义图标",
                  zoom: 13,
                });
                that.map.add(this.markerZhong);
              }

               let polyline = new AMap.Polyline({
                path: val.path[i][j],
                isOutline: true,
                outlineColor: "#ffeeff",
                borderWeight: 1,
                strokeColor: "#409EFF",
                strokeOpacity: 1,
                strokeWeight: 6,
                // 折线样式还支持 'dashed'
                strokeStyle: "solid",
                // strokeStyle是dashed时有效
                strokeDasharray: [10, 5],
                lineJoin: "round",
                lineCap: "round",
                zIndex: 2021,
                showDir:true,
              });
              arr.push(polyline)
              // that.map.add(polyline);
            }
            that.polyline.addOverlays(arr)
            that.map.add(this.polyline);
          }
        }

        let sql = "1=1";
        let equipment = "";
        let line = "";
        // if(val.equipment.length > 0) {
        //   equipment = "(type='point' and id in (" + val.equipment.join(',') + '))'
        // }
        // if(val.line.length > 0) {
        //   line = "((type in ('line')) and (id in (" + val.line.join(',') + ')))'
        // }
        // if(equipment) {
        //   spl = equipment
        // }
        // if (line) {
        //    spl = line
        // }
        // if(equipment && line) {
        //   spl = equipment + ' or ' + line
        // }
        // console.log(spl)
        let coverage = "";
        let pol = "";
        if (val.network && val.network.length > 0) {
          val.network.forEach((item) => {
            let link = "";
            if (coverage) {
              link = ",";
            }
            coverage = coverage + link + item.coverage;
            let link2 = "";
            if (pol) {
              link2 = ",";
            }
            let path = item.location;
            if (path && path.length > 0) {
              let newp = [];
              path.forEach((el) => {
                newp.push(el.join(" "));
              });
              newp = newp.join(",");
              pol = pol + link + "((" + newp + "))";
            }
          });

          if (coverage) {
            let coverages = [...new Set(coverage.split(","))].join(",");
            sql = sql + " and coverage in (" + coverages + ")";
          }
          if (pol) {
            sql = sql + " and INTERSECTS(location,MULTIPOLYGON(" + pol + "))";
          }
          sql =
            sql +
            " and tableName <> 'tb_basic_pipe' and tableName <> 'patrol_lines'";

          that.equipment = new AMap.TileLayer.WMS({
            url: "/geoserver/wms",
            tileSize: 512,
            dataZooms: [0, 20],
            zooms: [0, 20],
            zIndex: 2019,
            params: {
              LAYERS: "linqing:lineWalking", //"linqing:view_all_line", //layer,
              VERSION: "1.1.1",
              TRANSPARENT: true,
              //   timestamp: Date.parse(new Date()),
              CQL_FILTER: sql,
            },
          });
          that.map.add(that.equipment);
          that.drawLineSplic(coverage, pol, val.network); //管线切割
        }
        // 线
        // if(val.line && val.line.length>0){
        //   for(let i=0;i<val.line.length;i++){
        //     let polyline = new AMap.Polyline({
        //       path: val.line[i].coordinate,
        //       isOutline: true,
        //       outlineColor: "#ffeeff",
        //       borderWeight: 3,
        //       strokeColor: "#3366FF",
        //       strokeOpacity: 1,
        //       strokeWeight: 6,
        //       // 折线样式还支持 'dashed'
        //       strokeStyle: "solid",
        //       // strokeStyle是dashed时有效
        //       strokeDasharray: [10, 5],
        //       lineJoin: "round",
        //       lineCap: "round",
        //       zIndex: 50,
        //     });
        //     that.map.add(polyline);
        //   }
        // }
        // 调压柜
        let surge = require("@/assets/lineMap/surge.png");
        // 阀门
        let valve = require("@/assets/lineMap/valve.png");

        // 打卡点
        let img = require("@/assets/ydk.png");
        // 未打卡
        let imgwdk = require("@/assets/wdk.png");

        // 设备
        // if(val.equipment && val.equipment.length>0){
        //   for(let y=0;y<val.equipment.length;y++){

        //       // 阀井 / 阀室
        //         if(val.equipment[y].equipmentType == 721 || val.equipment[y].equipmentType == 722) {
        //           img = valve
        //         }
        //         let marker = new AMap.Marker({
        //           position: new AMap.LngLat(val.equipment[y].locations[0],val.equipment[y].locations[1]),
        //           offset: new AMap.Pixel(-10, -10),
        //           icon: img, // 添加 Icon 实例
        //           title: "自定义图标",
        //           zoom: 13,
        //         });
        //         that.map.add(marker);
        //   }
        // }
        // 打卡点
        // if (val.clock && val.clock.length > 0) {
        //   let labelall = []
        //   that.labelsLayer=new AMap.OverlayGroup()
        //   for (let y = 0; y < val.clock.length; y++) {
        //     let icon = new AMap.Icon({
        //       size: new AMap.Size(58, 70), // 图标尺寸
        //       image: val.clock[y].isFlag == 1 ? img : imgwdk, // Icon的图像地址
        //       imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
        //     });
        //     let labelsLayer = new AMap.Marker({
        //       position: new AMap.LngLat(
        //         val.clock[y].coordinate[0],
        //         val.clock[y].coordinate[1]
        //       ),
        //       offset: new AMap.Pixel(-10, -10),
        //       icon: icon, // 添加 Icon 实例
        //       title: "自定义图标",
        //       zoom: 13,
        //     });
        //     labelall.push(labelsLayer)
        //     // that.map.add(this.labelsLayer);
        //   }
        //   that.labelsLayer.addOverlays(labelall)
        //     that.map.add(that.labelsLayer);
        // }
        // 人员位置
        if (val.userLocation && val.userLocation.length > 0) {
          let personicon = new AMap.Icon({
            size: new AMap.Size(58, 70), // 图标尺寸
            image: require("@/assets/legend/xjr.png"), // Icon的图像地址
            imageSize: new AMap.Size(20, 30), // 根据所设置的大小拉伸或压缩图片
          });
          let personiconmarker = new AMap.Marker({
            position: new AMap.LngLat(val.userLocation[0], val.userLocation[1]),
            offset: new AMap.Pixel(-10, -10),
            icon: personicon, // 添加 Icon 实例
            title: "自定义图标",
            zoom: 13,
          });
          that.map.add(personiconmarker);
        }
        // 片区
        // console.log(val)
        if (val.network && val.network.length > 0) {
          let networkall = []
          that.polygonNet=new AMap.OverlayGroup()
          for (let z = 0; z < val.network.length; z++) {
            let polygonNet = new AMap.Polygon({
              path: val.network[z].location,
              strokeColor: "#A8DAEB",
              strokeWeight: 6,
              strokeOpacity: 0.2,
              fillOpacity: 0.4,
              fillColor: "#C3EEF6",
              zIndex: 2,
              bubble: true,
            });
            networkall.push(polygonNet)
            // that.map.add(this.polygonNet);
          }
          that.polygonNet.addOverlays(networkall)
            that.map.add(this.polygonNet);
        }
      });
    },
     layerChange(val){ 
        if(val.indexOf('gj')!=-1){
           if(this.polyline){
            this.polyline.show()
            if(this.datarow.path.length > 0) { 
              this.markerZhong.show()
              this.markerqi.show()
            }
           }
        }else{
           if(this.polyline){
            this.polyline.hide()
            if(this.datarow.path.length > 0) { 
              this.markerZhong.hide()
              this.markerqi.hide()
            }
           }
        }

        if(val.indexOf('gw')!=-1){
           if(this.wms){
            this.wms.show()
           }
        }else{
           if(this.wms){
            this.wms.hide()
           }
        }

        if(val.indexOf('pq')!=-1){
           if(this.polygonNet){
            this.polygonNet.show()
           }
        }else{
           if(this.polygonNet){
            this.polygonNet.hide()
           }
        }
        if(val.indexOf('dkd')!=-1){
           if(this.labelsLayer){
            this.labelsLayer.show()
           }
        }else{
           if(this.labelsLayer){
            this.labelsLayer.hide()
           }
        }
        console.log(val)
        if(val.indexOf('sb')!=-1){
           if(this.equipment){
            this.equipment.show()
           }
        }else{
           if(this.equipment){
            this.equipment.hide()
           }
        }
 


    },
    drawLineSplic(coverage, pol, network) {
      let that = this;
      this.wms = new AMap.OverlayGroup({
        collision: false,
        allowCollision: false,
      });
      // that.map.add(this.wms);

      let params = {
        SERVICE: "wfs",
        VERSION: "1.0.0",
        REQUEST: "GetFeature",
        typeName: "linqing:view_all_line",
        outputFormat: "application/json",
        //  viewparams: `netid:${row.networkId}`,
        CQL_FILTER:
          "coverage in (" +
          coverage +
          ") and INTERSECTS(location,MULTIPOLYGON(" +
          pol +
          "))",
      };
      if (!coverage) {
        params.CQL_FILTER = "1=2";
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      queryFeatrue(params).then((res) => {
        //调取管线
        loading.close();

        let newLine = [];
        let f = res.features;
        let line = [];
        f.forEach((item,index) => {
          let location = formatLocation(
            item.properties.location_str,
            "LineString"
          );
          for (let i = 1; i < location.length; i++) {
            let sub = [Number(location[i - 1][0]), Number(location[i - 1][1])];
            let sub1 = [Number(location[i][0]), Number(location[i][1])];
            line.push({ ...item.properties, line: [sub1, sub] });
          }
        });

        // py.push(py[0])//首尾相接
        for (let i = 0; i < network.length; i++) {
          let py = network[i].location;
          let line2 = turf.lineString(py);
          let pn = turf.polygon([py]);
          let splitter = turf.lineString(py);
          line.forEach((obj) => {
            //切割
            //  if(obj.network_id.indexOf(network[i].id)!=-1){
            let item = obj.line;
            let line1 = turf.lineString(item);
            let intersects = turf.lineIntersect(line1, line2);
            if (intersects.features.length < 1) {
              //没相交是面内
              let isHave = turf.booleanContains(pn, line1);
              if (isHave) {
                obj.line2 = item;
                newLine.push({ ...obj });
              }
            } else if (intersects.features.length > 0) {
              //一条线相交了两次及以上
              let linett = turf.lineString(item);

              let splitline = turf.lineSplit(linett, splitter);

              splitline.features.forEach((fea) => {
                let feaLine = fea.geometry.coordinates;
                //  let pn = turf.polygon([py]);
                let pl = turf.lineString(feaLine);
                let isHave2 = turf.booleanContains(pn, pl);

                if (isHave2) {
                  obj.line2 = feaLine;
                  newLine.push({ ...obj });
                }
              });
            }
            // }
          });
        }
        //  });
        let group = [];
        for (let i = 0; i < newLine.length; i++) {
          let newpolyline = new AMap.Polyline({
            path: newLine[i].line2,
            strokeColor: newLine[i].style_color,
            strokeOpacity: 1,
            strokeWeight: 2,
            strokeStyle: "solid",
            strokeDasharray: [10, 5],
            lineJoin: "round",
            lineCap: "round",
            zIndex: 2200,
          });
          //  this.map.add(polyline);
          group.push(newpolyline);
        }
        that.wms.addOverlays(group);
        that.map.add(that.wms);
      });
    },
  },
};
</script>